<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddOffer"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('OFFERS.ADD_OFFER.HEADER.TEXT')}}</p>
          <div class="default-input d-flex align-items-center">{{$t('OFFERS.ADD_OFFER.OFFER_NUMBERGROUP')}} 
            <vs-select :disabled="offer_nr.length > 0" v-model="numbergroup_fk" class="ml-2" :danger="numbergroup_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
            <vs-checkbox v-model="pricesLastYear">{{$t('OFFERS.ADD_OFFER.LABEL.OLDPRICES')}}</vs-checkbox>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <vs-input ref="bez" :label="$t('OFFERS.ADD_OFFER.LABEL.BEZ')"  class="float-left mr-2 inputx" :placeholder="$t('OFFERS.ADD_OFFER.LABEL.PLACEHOLDER.BEZ')"  v-model="bez" :danger="bez_fail" val-icon-danger="clear"/>
            <vs-select :label="$t('OFFERS.ADD_OFFER.LABEL.COUNTRY')" v-model="country_fk" class="float-left mr-2" :danger="country_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
            </vs-select>
            <vs-select :label="$t('OFFERS.ADD_OFFER.LABEL.WAEHRUNG')" v-model="currency_fk" :danger="currency_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencies" />
            </vs-select>
          </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';

export default {
  name: "AddOfferDlg",
  props:[],
  data: function (){
     return {
       dlgActive:false,
       numbergroups:[],
       popupTitle:this.$t('OFFERS.ADD_OFFER.TITLE'),
       country_fk:-1,
       debitor_fk:-1,
       debitorobject_fk:-1,
       offer_nr:"",
       numbergroup_fk:-1,
       country_fk_fail:false,
       country_fk_ok:false,
       numbergroup_fk_fail:false,
       numbergroup_fk_ok:false,
       currency_fk_fail:false,
       currency_fk_ok:false,
       currency_fk:-1,
       bez:"",
       bez_fail:false,
       bez_ok:false,
       pricesLastYear:false,
       defaultNrGrpFk:-1
     }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(deb_id,obj_id,country_fk){
        this.ClearForm();
        this.country_fk = country_fk;
        this.debitor_fk = deb_id;
        this.debitorobject_fk = obj_id;

        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }

        if(this.IsNotNull(this.currencies))
        {
          if(this.currencies.length > 1)
          {
            this.currency_fk = this.currencies[1].value;
          }
        }
        
        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.popupTitle = this.$t('OFFERS.ADD_OFFER.TITLE.EDIT') + ' ('+this.offer_nr+')';

          this.dlgActive=true;
        }
      },
      accepted(){

          var offer = {
            bez:this.bez,
            debitor_fk: this.debitor_fk,
            debitorobject_fk: this.debitorobject_fk,
            country_fk:this.country_fk,
            currency_fk:this.currency_fk,
            numbergroup_fk:this.numbergroup_fk,
            pricesLastYear:this.pricesLastYear,
            id:0
          };

          var name = this.bez;
          this.$store.dispatch('accounting/saveOffer', offer)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('debitor/getMyDebitorsMin', { root: true },{ root: true }); 
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.ADD_OFFER.SUCCESS.SAVE').replace("*1*",name)});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t('OFFERS.ADD_OFFER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('OFFERS.ADD_OFFER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.country_fk = -1;
        this.debitor_fk = -1;
        this.debitorobject_fk = -1;
        this.offer_nr = "";
        this.numbergroup_fk = -1;
        this.country_fk_fail = false;
        this.currency_fk = -1;
        this.bez = "";
        this.country_fk_fail = false;
        this.country_fk_ok = false;
        this.numbergroup_fk_fail = false;
        this.numbergroup_fk_ok = false;
        this.currency_fk_fail = false;
        this.currency_fk_ok = false;
        this.bez_fail = false;
        this.bez_ok = false;
        this.pricesLastYear=false;
      }
  },
  computed:{
    formValid(){
      return this.bez_ok && this.country_fk_ok && this.currency_fk_ok;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.ANGEBOT);
    },
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect(false);
    },
  },
  watch:{
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    country_fk(value){
      this.country_fk_fail = value <= 0;
      this.country_fk_ok = !this.country_fk_fail;
    },
    currency_fk(value){
      this.currency_fk_fail = value <= 0;
      this.currency_fk_ok = !this.currency_fk_fail;
    },
    numbergroup_fk(value){
      this.numbergroup_fk_fail = value <= 0;
      this.numbergroup_fk_ok = !this.numbergroup_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddOffer > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddOffer > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>