<template>
    <vs-card>
        <vs-list v-if="!text_only">
            <vs-list-header :title="title"></vs-list-header>
            <template  v-for="(item,idx) in data.member">               
                <vs-list-item :key="'item'+idx" :title="item.text" :subtitle="GetSubtitle(item)">
                   
                    <div class="clearfix">
                        <div class="float-right minW-50" v-if="data.showAmount">
                            <vs-input-number size="small" min="0" v-model="item.amount" class="inputx" />
                        </div>
                        <div v-show="item.ral_possible == true" class="float-right minW-50 mr-2">
                            <RalColorPicker v-model="item.ral_fk"></RalColorPicker>
                        </div>
                        <div class="float-right minW-50">
                            <span :class="myclass">{{item.brutto_txt}}</span>
                        </div>
                    </div>
                </vs-list-item>
            </template>
        </vs-list>
        <div  v-if="text_only">
            <template  v-for="(item,idx) in data.member">
                <p :key="'item'+idx" >{{item.text}}</p>
            </template>
        </div>
    </vs-card>
</template>
<script>

import RalColorPicker from './RalColorPicker';


export default {
  name: "PriceBlock",
   components: {
    RalColorPicker
  },
  props:[ 'title','data','myclass','text_only','no_amount'],
  data: function (){
     return {
     }
  },
  methods:{
      GetSubtitle(item){
          var retVal = "";
          if(this.text_only == false)
          {
              if(typeof(item) != 'undefined')
              {
                var size = this.$t('PRICES.BLOCK.SIZE')+' ' + item.length+'x'+item.width;
                var power = this.$t('PRICES.BLOCK.POWER')+' '+item.power+'W';

                if(item.length >0 && item.width > 0 && item.power > 0)
                {
                    retVal = size + ' ' + power;
                }
                else if(item.power > 0){
                    retVal = power;
                }else if(item.length >0 && item.width > 0){
                    retVal = size;
                }
              }
          }
          return retVal;
      }
  }
};
</script>