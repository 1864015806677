<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card class="vs-con-loading__container" id="pricesHeaderCard">
        <h2 class="card-title d-flex">{{$t('PRICES.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button :disabled="!downloadAllowed" @click="DownloadPdf()" id="btnDownloadPrices" class="mr-2 vs-con-loading__container" size="small" color="primary" type="filled" icon="get_app"></vs-button> 
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('PRICES.HEADER.TEXT')}}</span>
        </p>
        <div class="default-input d-flex align-items-center">
          <vs-select :label="$t('PRICES.LABEL.WAEHRUNG')" v-model="currency_fk">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencies" />
          </vs-select>
          <vs-select :label="$t('PRICES.LABEL.LAND')" class="ml-3 " v-model="country_fk">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
          </vs-select>
          <vs-select v-show="showPriceListSelect" :label="$t('PRICES.LABEL.PRICELIST')" class="ml-3 " v-model="actPriceListFk">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in pricelistsForSelect" />
          </vs-select>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
  
  <vs-row vs-justify="center">  
    <template v-for="(block, index) in blockList">
      <vs-col :key="'block_'+index" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="12" vs-xs="12">
        <PriceBlock myclass="price" :title="block.blockHeader" id_pstfx="_block_stand" :data="block" :text_only="block.isTextBlock"></PriceBlock>
      </vs-col>
    </template>
    <vs-col v-if="gesamtsumme > 0" type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="12" vs-xs="12">
      <vs-card>        
        <div>
          <h3>{{$t('PRICES.SUM_BLOCK.HEADER')}}</h3>
          <p> {{$t('PRICES.SUM_BLOCK.TEXT')}}</p>
        </div>
        <div class="default-input d-flex align-items-center mt-2">
          <vs-list>
            <vs-list-item class="prices_footer" :title="gesamtsummeAsText" >
              <can I="create" a="offer">  
                <vs-button type="filled" icon="add" @click="AddNewOffer()">{{$t('PRICES.CREATE_OFFER')}}</vs-button>
              </can>
              <can I="create" a="partnerorder">  
                <vs-button type="filled" icon="add" @click="AddNewPartnerOrder()">{{$t('PRICES.CREATE_PARTNERORDER')}}</vs-button>
              </can>
              <vs-button type="filled" class="ml-2" icon="clear" @click="ResetAmounts()">{{$t('PRICES.RESET')}}</vs-button>
            </vs-list-item>
          </vs-list>          
        </div>
      </vs-card>
    </vs-col>  
  </vs-row>
  <AddDebitorContactDlg ref="addDebitorContactDlg" :SelectDebAndObjOnly="true" v-on:DebAndObjSelected="ShowNewOfferDlg"></AddDebitorContactDlg>
  <AddOfferDlg ref="addOfferDlg" v-on:Success="OfferAdded"></AddOfferDlg>
  <AddPartnerOrderDlg ref="addPartnerOrderDlg" v-on:Success="NavigateToPartnerOrderById"></AddPartnerOrderDlg>
</div>
</template>

<script>


import helperMethods from '../helper/staticFuncHelper';
import PriceBlock from './components/PriceBlock';
import downloadMethods from '../helper/staticDownloadFuncHelper';
import AddDebitorContactDlg from './components/dialogs/AddDebitorContactDlg';
import AddOfferDlg from './components/dialogs/AddOfferDlg';
import AddPartnerOrderDlg from './components/dialogs/AddPartnerOrderDlg';
import codeHelper from '../helper/staticCodeHelper';
import { productVariantExtraOptions } from '../helper/enumHelper';

export default {
  name: "Prices",
  components: {
    PriceBlock,
    AddDebitorContactDlg,
    AddOfferDlg,
    AddPartnerOrderDlg
  },
    data: function (){
      return {
      pricelists:[],
      pricelist:null,
      actPriceListFk:0,
      currency_fk:-1,
      country_fk:-1,
      isLoading:false,
      defaultRal: this.GetRalDefault(),
      showPriceListSelect:false,
      blockList:[]
      };
  },
  mounted () {
    // Durch das Setzen der Auswahllisten auf ihren ersten Eintrag wird das Laden der Daten über den Watcher angeworfen
      if(this.countries.length > 1 && this.currencies.length > 1)
      {
        this.country_fk = this.countries[1].value;
        this.currency_fk = this.currencies[1].value;
      }
    },
  computed: 
  { 
    pricelistsForSelect(){
      var ret = [];
      
      if(this.$store.state.pricelists.valid_pricelists.data != null)
      {
        for (let i = 0; i < this.$store.state.pricelists.valid_pricelists.data.length; i++)
        {
            var val = {"text": this.$store.state.pricelists.valid_pricelists.data[i].pricelist.bez, "value":this.$store.state.pricelists.valid_pricelists.data[i].pricelist.id};
            ret.push(val);
        }        
      }
      return ret;
    },
    getData() {
      var data = [];
      if(this.$store.state.pricelists.valid_pricelists.data != null)
      {
        data = this.$store.state.pricelists.valid_pricelists.data;
      }
      return data;
    },
    downloadAllowed(){
      var retVal = false;
      if(this.pricelist != null)
      {
        retVal = this.pricelist.doc_id > 0 ? true:false;
      }
      return retVal;
    },
    dataLoaded()
    {
      if(this.pricelist != null)
        return true;
      else
        return false;
    },
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect(false);
    },
    ralCardPriceBrutto(){
      var price = 0;
      if(this.IsNotNull(this.pricelist))
      {
        for (let i = 0; i < this.pricelist.member.length; i++) {
          if(this.pricelist.member[i].isRalKarte == true)
          {
            var mwst = this.GetMwst(this.pricelist.member[i].mwst_fk);
            price = this.CalcBrutto(mwst.value, this.pricelist.member[i].netto);
            break;
          }
        }
      }
      return price;
    },
    ralStdPriceBrutto(){
      var price = 0;
      if(this.IsNotNull(this.pricelist))
      {
        for (let i = 0; i < this.pricelist.member.length; i++) {
          if(this.pricelist.member[i].isRalBestand == true)
          {
            var mwst = this.GetMwst(this.pricelist.member[i].mwst_fk);
            price = this.CalcBrutto(mwst.value, this.pricelist.member[i].netto);
            break;
          }
      }
     }
      return price;
    },
    gesamtsummeAsText(){
      var retVal = "";
      if(this.pricelist != null )
      {
        retVal = this.DecimalToString(this.gesamtsumme,this.pricelist.currency.kuerzel,this.GetLocalFormatOfLanguage(this.pricelist.country.language_fk));
      }
      return retVal;
    },
    gesamtsumme(){

      var val = Number(0);
      var ralStd = [];
      var ralCard = [];

      this.blockList.forEach(block => {
        if(block.isTextBlock == false)
        {
          block.member.forEach(mem => {
            val += mem.brutto * mem.amount;

            if(mem.ral_fk > 0 && mem.ral_fk != this.defaultRal.id && mem.amount > 0)
            {
              var ralcolor = this.GetRalById(mem.ral_fk);
              if(ralcolor != null)
              {
                if(ralcolor.available)
                {
                  if(!ralStd.includes(mem.ral_fk))
                  {
                    ralStd.push(mem.ral_fk);
                  }
                }
                else
                {
                  if(!ralCard.includes(mem.ral_fk))
                  {
                    ralCard.push(mem.ral_fk);
                  }
                }
              }
            }

          });
        }
      });
      val += ralStd.length * this.ralStdPriceBrutto;
      val += ralCard.length * this.ralCardPriceBrutto;

      return val;
    },
    selectedBlas(){
      var data = [];
          this.blockList.forEach(block => {
            block.member.forEach((mem,idx )=> {
              if(mem.amount > 0)
              {
                var offermem = {
                  id:0,
                  offer_fk:1,
                  product_fk:mem.product_fk,
                  product_bez:mem.text,
                  article_nr:mem.article_nr,
                  ral_fk:mem.ral_fk,
                  motif_fk:0,
                  motif_netto:0,
                  motif_orientation:0,
                  netto:mem.netto,
                  brutto:mem.brutto,
                  amount:Number(mem.amount),
                  mwst_fk:mem.mwst_fk,
                  mwst_val:mem.mwst_val,
                  currency_kuerzel: this.pricelist.currency.kuerzel,
                  language_fk:this.pricelist.country.language_fk,
                  orderNr:idx+1,
                  surface_txt:"",
                  text:"",
                  productVariants:[]
                };

                if(block.blockVariantId > 0)
                {
                  offermem.productVariants.push(
                    {
                      id:0,
                      product_variant_fk:block.blockVariantId,
                      product_variant_group_fk:block.blockVariantGroupId,
                      netto:mem.variant_netto
                    }
                  );
                }

                data.push(offermem);
              }
            });
          });
          return data;
    }
  },
  watch: {
      actPriceListFk(value){
        this.ShowPricelistById(value);
      },
      getData(value)
      {
          if(!this.IsEmpty(value) && this.IsNotNull(value))
          {
            this.pricelists = value;
            this.actPriceListFk = this.pricelists[0].pricelist.id;
            this.showPriceListSelect =  this.pricelists.length > 1;
            if(this.showPriceListSelect == false)
            {
              this.ShowPricelistById(this.actPriceListFk );
            }
          }
      },
      currency_fk(value)
      {
        if(this.country_fk != -1 && value != -1)
        {
          this.LoadData();
        }
      },
      country_fk(value)
      {
        if(this.currency_fk != -1 && value != -1)
        {
          this.LoadData();
        }
      }
    },
  methods: {    
      ...helperMethods,
      ...downloadMethods,
      ...codeHelper,
      ShowPricelistById(id){

        if( !this.IsEmpty(this.pricelists))
        {
          for(var i = 0; i < this.pricelists.length; i++)
          {
            if(this.pricelists[i].pricelist.id == id)
            {
              this.pricelist = this.pricelists[i];
              this.FillBlockList();
              break;
            }
          }
        }
      },
      NavigateToPartnerOrderById(id)
      {
        if(id > 0)
        {
          this.$router.push({ name: 'PartnerOrder', params: { partnerorder_fk: id, editable:true } });
        }
        
      },   
      AddNewPartnerOrder(){
        this.$refs.addPartnerOrderDlg.ShowDlgFromPrices(this.GetSelectedDataForPartnerOrder());
      },   
      LoadData: function ()
      {
        if(this.isLoading == false)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#pricesHeaderCard'});
          this.isLoading = true;
          this.ClearData();
            if(this.currency_fk != -1 && this.country_fk != -1)
            {
              this.$store.dispatch('ralcolor/getAllRalColors',{ root: true },{ root: true });

              this.$store.dispatch('pricelists/getPrices',{currency_fk:this.currency_fk,country_fk:this.country_fk,lastYear:false})
              .then(data => {
                  if(typeof(data) != 'undefined')
                  {
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRICES.SUCCESS.LOAD')});
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICES.ERROR.LOAD')});
                  }
                  this.isLoading = false;
                  this.$store.dispatch('alertqueue/hideLoader', {'id':'#pricesHeaderCard'});
                }, 
                error =>{
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICES.ERROR.LOAD')+' '+error});
                  this.isLoading = false;
                  this.$store.dispatch('alertqueue/hideLoader', {'id':'#pricesHeaderCard'});
                }); 
            }
        }
      },
      DownloadPdf(){
        if(this.pricelist != null )
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#btnDownloadPrices', 'scale':0.4});       
          this.$store.dispatch('document/downloadDocument', this.pricelist.doc_id)
          .then(response => {
              this.$store.dispatch('alertqueue/hideLoader', {'id':'#btnDownloadPrices'});
              this.DownloadWithFileSaver(response);
            }, 
            error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICES.ERROR.DOWNLOAD')+' '+error});
              this.$store.dispatch('alertqueue/hideLoader', {'id':'#btnDownloadPrices'});
            }
          );
        }
      },
      ClearData(){
        this.blockList = [];
      },
      ResetAmounts(){
        this.blockList.forEach(block => {
        if(block.isTextBlock == false)
        {
          block.member.forEach(mem => {
            mem.amount = 0; 
          });
        }
      });
      },
      FillBlockList(){

        this.blockList = [];

        this.pricelist.member.forEach(mem => {
          var product = this.GetProduct(mem.product_fk);
          var mwst = this.GetMwst(mem.mwst_fk);

          var allowed = false;
          if(this.IsNotNull(product))
          {
            // RAL Einträge haben zwar ein Produkt und mwst aber sind nicht bestellbar
            if (product.bestellbar == true || mem.isRalBestand || mem.isRalKarte)
            {
                allowed = true;
            }
          }
          else
          {
              // wenn nur text gedruckt wird brauchen wir kein prod oder mwst
              if(mem.use_text )
              {
                  allowed = true;
              }
          }

          if(allowed)
          {
            if (mem.product_fk > 0)
            {
              var product_categories = this.GetProductCategoriesForProdukt(mem.product_fk);
              var cat = null;
              if(product_categories.length > 0)
              {
                cat = product_categories[0];
              }
              if(cat != null)
              {
                var block_idx = this.GetBlockIndexInList(cat.id,0,false);
                if(block_idx == -1)
                {
                  var block = {
                    blockId: cat.id,
                    blockVariantId:0,
                    blockVariantGroupId:0,
                    isTextBlock:false,
                    blockHeader:cat.name,
                    order:cat.printerOrder*100,
                    showAmount:true,
                    member:[]
                    };
                  
                  if(mem.isRalBestand == true || mem.isRalKarte == true || mem.use_text)
                  {
                    block.showAmount = false;
                  }

                  this.blockList.push(block);
                  block_idx = this.GetBlockIndexInList(cat.id,0,false);
                  
                }
                if(block_idx != -1)
                {
                  var text = product.bez;
                  if(mem.use_text && mem.text.length > 0)
                  {
                    text = mem.text;
                  }

                  var ralPossible = false;
                  mem.variantMember.forEach(varmem => {
                    var variant = this.GetProductVariant(varmem.productvariant_fk);
                    if(variant != null)
                    {
                      if(variant.extraoption == productVariantExtraOptions.RALPICKER)
                      {
                        ralPossible = true;
                      }
                    }
                  });


                  this.blockList[block_idx].member.push(
                    {
                      product_fk:product.id,
                      article_nr:product.article_nr,
                      netto:mem.netto,
                      variant_netto:0,
                      brutto_txt:this.DecimalToString(this.CalcBrutto(mwst.value, mem.netto),this.pricelist.currency.kuerzel,this.GetLocalFormatOfLanguage(this.pricelist.country.language_fk)),
                      brutto:this.CalcBrutto(mwst.value, mem.netto),
                      text:text,
                      amount:0,
                      power:product.power,
                      width:product.width,
                      length:product.length,
                      ral_fk:0,
                      ral_possible:ralPossible,
                      mwst_fk: mwst.id,
                      mwst_val: mwst.value
                    });
                }

                mem.variantMember.forEach(variantmem => {
                  if(variantmem.print == true)
                  {
                    var variant = this.GetProductVariant(variantmem.productvariant_fk);
                    if(variant != null)
                    {
                      var block_variant_idx = this.GetBlockIndexInList(-2,variantmem.productvariant_fk,false);
                      if(block_variant_idx == -1)
                      {
                        var variantgroup = this.GetProductVariantGroupForVariantId(variantmem.productvariant_fk);

                        var variantblock = {
                        blockId: -2,
                        blockVariantId:variantmem.productvariant_fk,
                        blockVariantGroupId: variantgroup != null ? variantgroup.id:0,
                        isTextBlock:false,
                        blockHeader:variant.label_long,
                        order:this.blockList[block_idx].order + variantmem.order + 1,
                        showAmount:true,
                        member:[]
                        };
                        this.blockList.push(variantblock);
                        block_variant_idx = this.GetBlockIndexInList(-2,variantmem.productvariant_fk,false);
                      }
                      if(block_variant_idx != -1)
                      {
                        this.blockList[block_variant_idx].member.push(
                          {
                            netto:mem.netto,
                            variant_netto:variantmem.netto,
                            product_fk:product.id,
                            article_nr:product.article_nr,
                            brutto_txt:this.DecimalToString(this.CalcBrutto(mwst.value, variantmem.netto + mem.netto),this.pricelist.currency.kuerzel,this.GetLocalFormatOfLanguage(this.pricelist.country.language_fk)),
                            brutto:this.CalcBrutto(mwst.value, variantmem.netto + mem.netto),
                            text:product.bez,
                            amount:0,
                            power:0,
                            width:0,
                            length:0,
                            ral_fk:0,
                            ral_possible:false,
                            mwst_fk: mwst.id,
                            mwst_val: mwst.value
                          });
                      }
                    }
                  }
                });
              }
            }
            else
            {
              if(mem.use_text)
              {
                var textblock_idx = this.GetBlockIndexInList(-1,0,true);
                if(textblock_idx == -1)
                {
                  var textblock = {
                      blockId: -1,
                      blockVariantId:0,
                      blockVariantGroupId:0,
                      isTextBlock:true,
                      blockHeader:"",
                      order:10001,
                      showAmount:false,
                      member:[]
                      };
                  this.blockList.push(textblock);
                  textblock_idx = this.GetBlockIndexInList(-1,0,true);
                }
                if(textblock_idx != -1)
                {
                  this.blockList[textblock_idx].member.push(
                    {
                      product_fk:0,
                      article_nr:"",
                      netto:0,
                      variant_netto:0,
                      brutto_txt:"",
                      brutto:0,
                      text:mem.text,
                      amount:0,
                      power:0,
                      width:0,
                      length:0,
                      ral_fk:0,
                      ral_possible:false  ,
                      mwst_fk:0,
                      mwst_val:0                    
                      });
                }
              }
            }
          }
        });
      },
      GetBlockIndexInList(blockid,blockVariantId,istext){
        var index = -1;
        for (let i = 0; i < this.blockList.length; i++) {
          if(this.blockList[i].blockId == blockid && this.blockList[i].isTextBlock == istext && this.blockList[i].blockVariantId == blockVariantId)
          {
            index = i;
            break;
          }
          
        }
        return index;
      },
      GetProduct(id)
      {
        var retVal = null;
        if(this.pricelist != null )
        {
          if(typeof(this.pricelist.products) != 'undefined')
          {
            for(var i = 0; i < this.pricelist.products.length;i++)
            {
              var prod = this.pricelist.products[i];
              if(prod.id == id)
              {
                retVal = prod;
                break;
              }
            }
          }
        }
        return retVal;
      },
      GetProductVariant(id)
      {
        var variant = null;

        if(this.IsNotNull(this.pricelist) && !this.IsEmpty(this.pricelist))
        {
          if(this.IsNotNull(this.pricelist.productvariants) && !this.IsEmpty(this.pricelist.productvariants))
          {
            for (let i = 0; i < this.pricelist.productvariants.length; i++) {
              if(this.pricelist.productvariants[i].id == id)
              {
                variant = this.pricelist.productvariants[i];
                break;
              }
              
            }
          }
        }

        return variant;
      },
      GetProductVariantGroupForVariantId(id)
      {
        var grp = null;

        if(this.IsNotNull(this.pricelist) && !this.IsEmpty(this.pricelist))
        {
          if(this.IsNotNull(this.pricelist.productvariantgroups) && !this.IsEmpty(this.pricelist.productvariantgroups))
          {
            for (let i = 0; i < this.pricelist.productvariantgroups.length; i++)
            {
              for (let j = 0; j < this.pricelist.productvariantgroups.length; j++) 
              {
                for (let k = 0; k < this.pricelist.productvariantgroups[j].member.length; k++) {
                  if(this.pricelist.productvariantgroups[j].member[k].product_variant_fk == id)
                  {
                    grp = this.pricelist.productvariantgroups[j];
                    break;
                  }
                  
                }
               
              }
              
            }
          }
        }

        return grp;
      },
      GetProductCategoriesForProdukt(id)
      {
        var retVal = [];
        if(this.IsNotNull(this.pricelist) && !this.IsEmpty(this.pricelist))
        {
          if(this.IsNotNull(this.pricelist.productcategories) && !this.IsEmpty(this.pricelist.productcategories))
          {
            this.pricelist.productcategories.forEach(cat => {
              cat.member.forEach(mem => {
                if(mem.product_fk == id)
                {
                  retVal.push(cat);
                }
              });
            });
          }
        }
        return retVal;
      },
      GetMwst(id){
        var retVal = null;
        if(this.pricelist != null )
        {
          if(typeof(this.pricelist.mwStList) != 'undefined')
          {
            for(var i = 0; i < this.pricelist.mwStList.length;i++)
            {
              var mwst = this.pricelist.mwStList[i];
              if(mwst.id == id)
              {
                retVal = mwst;
                break;
              }
            }
          }
        }
        return retVal;
      },
      AddNewOffer()
      {
        this.$refs.addDebitorContactDlg.showDlg();
      },
      ShowNewOfferDlg(debitor_fk,debitorobject_fk){
          this.$refs.addOfferDlg.ShowDlg(debitor_fk,debitorobject_fk,this.country_fk);
      },

      GetSelectedDataForOffer(offer_fk){
        var data = [];
          this.blockList.forEach(block => {
            block.member.forEach((mem,idx )=> {
              if(mem.amount > 0)
              {
                var offermem = {
                  id:0,
                  offer_fk:offer_fk,
                  product_fk:mem.product_fk,
                  product_bez:mem.text,
                  article_nr:mem.article_nr,
                  ral_fk:mem.ral_fk,
                  motif_fk:0,
                  motif_netto:0,
                  motif_orientation:0,
                  netto:mem.netto,
                  brutto:mem.brutto,
                  amount:Number(mem.amount),
                  mwst_fk:mem.mwst_fk,
                  mwst_val:mem.mwst_val,
                  currency_kuerzel: this.pricelist.currency.kuerzel,
                  language_fk:this.pricelist.country.language_fk,
                  orderNr:idx+1,
                  surface_txt:"",
                  text:"",
                  productVariants:[]
                };

                if(block.blockVariantId > 0)
                {
                  offermem.productVariants.push(
                    {
                      id:0,
                      product_variant_fk:block.blockVariantId,
                      product_variant_group_fk:block.blockVariantGroupId,
                      netto:mem.variant_netto
                    }
                  );
                }

                data.push(offermem);
              }
            });
          });
          return data;
      },
      GetSelectedDataForPartnerOrder(){
        var data = [];
          this.blockList.forEach(block => {
            block.member.forEach((mem,idx )=> {
              if(mem.amount > 0)
              {
                var partnerordermem = {
                  id:0,
                  product_fk:mem.product_fk,
                  product_bez:mem.text,
                  article_nr:mem.article_nr,
                  ral_fk:mem.ral_fk,
                  motif_fk:0,
                  motif_netto:0,
                  motif_orientation:0,
                  netto:mem.netto,
                  brutto:mem.brutto,
                  amount:Number(mem.amount),
                  mwst_fk:mem.mwst_fk,
                  mwst_val:mem.mwst_val,
                  currency_kuerzel: this.pricelist.currency.kuerzel,
                  language_fk:this.pricelist.country.language_fk,
                  orderNr:idx+1,
                  surface_txt:"",
                  productVariants:[]
                };

                if(block.blockVariantId > 0)
                {
                  partnerordermem.productVariants.push(
                    {
                      id:0,
                      product_variant_fk:block.blockVariantId,
                      product_variant_group_fk:block.blockVariantGroupId,
                      netto:mem.variant_netto
                    }
                  );
                }

                data.push(partnerordermem);
              }
            });
          });
          return data;
      },
      OfferAdded(id)
      {
        var data = this.GetSelectedDataForOffer(id);

          this.$store.dispatch('accounting/insertOfferMembers', data)
          .then(response => {
            if(response.success == true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.OFFERMEMBERS.SUCCESS.SAVE')});  
            }
            else
            {
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('OFFERS.OFFERMEMBER.WARNING.SAVE')}); 
            }
            this.$emit('Accepted');
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('OFFERS.OFFERMEMBER.ERROR.SAVE')+' ' + error});  
              this.$emit('Accepted');
          });
      }
  }
};

</script>
<style>
.price{
  font-size:20px;
  margin-right:15px;
  margin-left:10px;
}
.vs-list--icon{
  padding:0px;
}
div.RAL_LIST > div.vs-list--item > div > i {
  font-size: 40px !important;
}
div.prices_footer > div.list-titles{
  font-size:20px;
}
.vs-list--header {
  font-size:120%;
}
</style>